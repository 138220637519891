import CompRebateLayoutOne from '@/components/rebate/comp-rebate-layout-one/index.vue';
import rebateMixin from '@/mixins/nov-new-rebate';
import { getTeamPurchDetail } from '@/service/rebate/novNewRebate';
import RebateTable from '@/components/rebate/rebate-table/index.vue';
import EmptyList from '@/components/common/empty-list/index.vue';

export default {
    name: 'team-purch-detial',
    mixins: [rebateMixin],
    components: {
        CompRebateLayoutOne,
        RebateTable,
        EmptyList,
    },

    data() {
        return {};
    },

    computed: {
        //  订单列表
        orderList() {
            const { rebateInfo } = this;
            if (rebateInfo) {
                return rebateInfo.detailList || [];
            }
            return [];
        },
    },

    methods: {
        //  初始化页面
        init() {
            const { startTime, endTime, agentId } = this;
            getTeamPurchDetail({
                startTime,
                endTime,
                userId: agentId,
            }).then(data => {
                this.rebateInfo = data;
            });
        },

        changeSelectedActivity(value) {
            this.curActivity = value;
        },

        changeSelectedSystem(value) {
            this.curSystem = value;
        }
    },
};
